import { Flex, Typography } from "antd"
import { FC, useState } from "react"
import SpinLoader from "../ui/SpinLoader"
import { SvgIcon } from "../icon"
import './index.less'
import ButtonWithIcon from "../ui/ButtonWithIcon"
import { useNavigate } from "react-router"
import { Routes } from "src/types/routes"
import { useLeadsApi } from "src/hooks/useLeadsApi"

type Props = {
  transactionId: Maybe<string>
  onClose: () => void
}

export const LeadsSideActionCard: FC<Props> = ({
  transactionId,
  onClose
}) => {
  const navigate = useNavigate()
  const { leadTransactionById } = useLeadsApi()
  const [shouldRefetch, setShouldRefetch] = useState(true);

  const { data } = leadTransactionById(transactionId ?? '', {
    refetchInterval: shouldRefetch ? 5000 : false,
    onSuccess: (data: any) => {
      if (data?.status === 'completed') {
        setShouldRefetch(false);
      }
    },
  });

  const status = data?.status
  
  const onExpand = () => {
    navigate(
      { pathname: Routes.ENHANCE_AND_SHARE }, 
      { state: { step: 2 } }
    )
  }

  const getCardData = (
    type: "upload" | "download" | "share" | undefined,
    status: "pending" | "completed" | undefined
  ) => {
    if (!type || !status) {
      return {
        title: "",
        transactionTypeTitle: "",
        description: "",
      };
    }
  
    const title = status === "pending" ? "Processing..." : "Processing Complete!";
  
    const transactionTypeTitle = () => {
      switch (type) {
        case "upload":
          return "Upload Files";
        case "download":
          return "Download Files";
        case "share":
          return "Share Leads";
        default:
          return "";
      }
    };
  
    const description = () => {
      if (status === "pending") {
        switch (type) {
          case "upload":
            return "Checking your file for errors...";
          case "download":
            return "Preparing your files for download...";
          case "share":
            return "Sharing leads...";
          default:
            return "";
        }
      } else if (status === "completed") {
        switch (type) {
          case "upload":
            return "Your file has been added to the system.";
          case "download":
            return "Files are ready to download.";
          case "share":
            return "Your leads are ready to be shared.";
          default:
            return "";
        }
      }
      return "";
    };
  
    return {
      title,
      transactionTypeTitle: transactionTypeTitle(),
      description: description(),
    };
  };

  return (
    <Flex className={"leads-side-action-card"} vertical gap={8}>
      <Flex justify={'space-between'} align={"center"}>
        <Typography.Text className={'process-name'}>
          {getCardData(data?.type, status).transactionTypeTitle}
        </Typography.Text>

        <Flex gap={8}>
          <ButtonWithIcon iconType={'arrowsOut'} onClick={onExpand} />
          {status === 'completed' && <ButtonWithIcon className={'close-button'} iconType={'xMark'} onClick={onClose} />}
        </Flex>
      </Flex>

      <Flex gap={12} vertical align={"center"} className={'process-status-wrapper'}>
        {status === "pending" ? <SpinLoader /> : <SvgIcon height={32} width={32} type={'checkmarkCircle'} />}

        <Flex vertical align={"center"} className={'process-info'}>
          <Typography.Text className={'process-title'}>
            {getCardData(data?.type, status).title}
          </Typography.Text>

          <Typography.Text className={'process-description'}>
            {getCardData(data?.type, status).description}
          </Typography.Text>
        </Flex>
      </Flex>
    </Flex>
  )
}