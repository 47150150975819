import { LeadsFilter } from "src/module/all-leads"
import axios from "axios"
import { App } from "src/types"
import fetchClient from "./fetchClient"

export interface LeadsResponse {
  type: "all" | "my-leads" | "new-leads" | "shared"
  page: number
  total: number
  limit: number
  data: App.Lead[]
}

export const getMyLeads = async (
  type: "all" | "my-leads" | "new-leads" | "shared",
  page: number,
  limit: number,
  filters: LeadsFilter
): Promise<LeadsResponse> => {
  const res = await fetchClient.get("/leads/mine", {
    params: {
      type,
      page,
      limit,
      lead_locations: filters.lead_locations?.join(','),
      lead_interests: filters.lead_interests?.join(','),
      lead_industries_id: filters.lead_industries_id?.join(','),
      lead_status: filters.lead_status,
      lead_networthmin: filters.lead_networthmin,
      lead_networthmax: filters.lead_networthmax,
      lead_locations_nodata: filters.lead_locations_nodata || undefined,
      lead_interests_nodata: filters.lead_interests_nodata || undefined,
      lead_industries_nodata: filters.lead_industries_nodata || undefined,
      lead_networth_nodata: filters.lead_networth_nodata || undefined,
    },
  })
  return res.data
}

export const getMyLeadsEnhancePreview = async (ids: string[]) => {
  const res = await fetchClient.post("/leads/enhance-preview", {
    ids,
  })
  return res.data
}

export const getMyLeadsEnhance = async (ids: string[]) => {
  const res = await fetchClient.post("/leads/enhance", {
    ids,
  })
  return res.data
}

export const getLeadsTransactionById = async (id: string) => {
  const res = await fetchClient.get(`/leads/transaction/${id}`)
  return res.data
}

export const getLeadsTransactions = async (type: "upload" | "download", page: number, limit: number) => {
  const res = await fetchClient.get("/leads/transactions", {
    params: {
      type,
      page,
      limit,
    },
  })
  return res.data
}

export const createLeadsExportRequest = async (ids: string[]) => {
  const res = await fetchClient.post("/leads/export", {
    ids,
  })
  return res.data
}
