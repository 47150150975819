import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { FC,useEffect,useState } from "react";
import { useLocation,useNavigate } from "react-router-dom";
import Loader from "src/components/layouts/Loader";
import { useAuth } from "src/contexts/AuthProvider";
import { handleSubscribe } from "src/helpers/handleSubscribe";
import { CardCredits } from "./assets/CardCredits";
import { CardSubscriptionInfo } from "./assets/CardSubscriptionInfo";
import { SubscriptionCard } from "./assets/SubscriptionCard"
import "./index.less";
import { updateProfile } from "../../api/userApi"

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const subscriptionPlans = {
  free: {
    title: "Free Plan",
    text: "For getting started with essentials.",
    price: "Free",
    features: ["Search by category", "Use earned credits"],
  },
  standard: {
    title: "Standard Plan",
    text: "Easily narrow results by cities or states.",
    price: "$300",
    features: ["300 credits/month", "Search by category", "Search by city or state"],
  },
  plus: {
    title: "Plus Plan",
    text: "Refine searches with demographics.",
    price: "$400",
    features: ["400 credits/month", "Search by category", "Search by city or state", "Add demographic filters"],
  },
  pro: {
    title: "Pro Plan",
    text: "Gain deeper financial insights.",
    price: "$500",
    features: [
      "500 credits/month",
      "Search by category",
      "Search by city or state",
      "Add demographic filters",
      "Search by net worth",
    ],
  },
}

const publicKey = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY

const stripePromise = loadStripe(publicKey);

export const Subscription: FC = () => {
  const { user, isLoading } = useAuth()
  const [subscriptions, setSubscriptions] = useState<any>([])
  const query = useQuery()
  const subscriptionQuery = query.get('subscription')
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${import.meta.env.VITE_BACKEND_URL}/api/v1/payments/products`)
      .then(res => setSubscriptions(res.data))
  }, [user])

  const subscriptionNameToPass = () => {
    switch (subscriptionQuery) {
      case "Standard_Plan_Subscription": {
        return {
          name: "basic",
          credits: 300
        };
      }
      case "Plus_Plan_Subscription": {
        return {
          name: "premium",
          credits: 400
        };
      }
      case "Pro_Plan_Subscription": {
        return {
          name: "enterprise",
          credits: 500
        };
      }
      default:
        return null;
    }
  }

  useEffect(() => {
    if (subscriptionQuery) {
      updateProfile({
        subscriptionType: subscriptionNameToPass()?.name,
        credits: user?.credits as number + subscriptionNameToPass()?.credits! as number
      })
        .then(() =>  navigate(location.pathname, { replace: true }))
        .then(() => window.location.reload())
    }
  }, [])

  return (
    <div className="subscription">
    <div className={"topper-cards"}>
      <CardSubscriptionInfo />
      <CardCredits credits={isLoading ? <Loader spinning size={"large"} /> : user?.credits} />
    </div>
    <div className={"subscription-cards"}>
      {subscriptions.length > 0 && <Elements stripe={stripePromise}>
        <SubscriptionCard plan={subscriptionPlans.free} isActive={user?.subscriptionType === "free"} />
        <SubscriptionCard plan={subscriptionPlans.standard} productId={subscriptions?.[2].id}
                          handleSubscribe={handleSubscribe}
                          isActive={user?.subscriptionType === "basic"} />
        <SubscriptionCard plan={subscriptionPlans.plus} productId={subscriptions?.[1].id}
                          handleSubscribe={handleSubscribe}
                          isActive={user?.subscriptionType === "premium"} />
        <SubscriptionCard plan={subscriptionPlans.pro} productId={subscriptions?.[0].id}
                          handleSubscribe={handleSubscribe}
                          isActive={user?.subscriptionType === "enterprise"} />
      </Elements>}
    </div>
    </div>
  )
}
