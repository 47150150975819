import { Layout as BaseLayout, Flex } from "antd"
import { FC, Suspense, useEffect, useState } from "react"
import { Outlet, useLocation } from "react-router-dom"
import './Layout.less'
import { Header } from "./Header"
import FullScreenLoader from "src/components/ui/FullScreenLoader"
import { Sidebar as SearchAndDownloadSidbar } from "src/module/search-and-download/assets/Sidebar"
import { Sidebar as LearningCenterSidebar } from "src/module/learning-center/assets/Sidebar"
import { Sidebar as MyLeadsSidebar } from "src/module/my-leads/assets/Sidebar"
import { Sidebar as ProfileSidebar } from "src/module/profile/assets/Sidebar"
import { LeadsSideActionCard } from "src/components/leads-side-action-card"
import { Routes } from "src/types/routes"
import { useApp } from "src/components/app"

const Layout: FC = () => {  
  const location = useLocation()
  const { showActionCard, setShowActionCard } = useApp().app

  const sidebarMap: Record<string, JSX.Element> = {
    [Routes.SEARCH_AND_DOWNLOAD]: <SearchAndDownloadSidbar />,
    [Routes.LEARNING_CENTER]: <LearningCenterSidebar />,
    [Routes.MY_LEADS]: <MyLeadsSidebar />,
    [Routes.PROFILE]: <ProfileSidebar />
  };
  
  const renderSidebar = (): JSX.Element | null => {
    const route = Object.keys(sidebarMap).find(key => location.pathname.includes(key));
    return route ? sidebarMap[route] : null;
  };

  const transactionId = JSON.parse(localStorage.getItem('lastTransaction') || '{}')?.transactionId;

  useEffect(() => {
    if (!transactionId) {
      setShowActionCard(false)
    }
  }, [setShowActionCard, transactionId])

  const onClearTransactionId = () => {
    localStorage.removeItem('lastTransaction');
    setShowActionCard(false);
  }

  return (
    <BaseLayout className={'enhance-and-share-layout'}>
      <BaseLayout.Header className={'header-wrapper'}>
        <Header />
      </BaseLayout.Header>

      <BaseLayout style={{ background: 'none', maxWidth: '1440px', width: '100%' }}>
        <BaseLayout.Sider width={280} style={{ background: 'none' }}>
          <Flex vertical justify={'space-between'} style={{ position: 'relative', height: '100%', paddingLeft: '32px' }}>
            {renderSidebar()}
            {showActionCard && <LeadsSideActionCard transactionId={transactionId} onClose={onClearTransactionId} />}
          </Flex>
        </BaseLayout.Sider>
        
        <BaseLayout.Content className={'content-wrapper'}>
          <Suspense fallback={<FullScreenLoader />}>
            <Outlet />
          </Suspense>
        </BaseLayout.Content>
      </BaseLayout>
    </BaseLayout>
  )
}

export { Layout }
