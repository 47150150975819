import { FC } from "react";
import { Spin } from "antd";
import { ReactComponent as SpinIcon } from "src/assets/icons/spin.svg"
import './index.less'

const SpinLoader: FC = () => {
  return (
    <Spin spinning indicator={<SpinIcon className={'spin'} />} size="large" />
  )
}

export default SpinLoader