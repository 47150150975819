import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes } from "src/types/routes";
import './Sidebar.less'
import { OrderedList } from "src/components/ordered-list";
import { Button, Flex } from "antd";
import { SvgIcon } from "src/components/icon";
import { UnorderedNavigationList } from "src/components/navigation-unordered-list";

export const Sidebar: FC = () => {
  const location = useLocation()
  const activeCampaignItem = location.state?.step.toString()
  const navigate = useNavigate()

  const campaignLocation = location.pathname.includes(`${Routes.CAMPAIGN}/`)

  const sidebarList = [
    { key: '1', title: 'Leads', path: Routes.MY_LEADS_ALL_LEADS },
    { key: '2', title: 'Uploads', path: Routes.MY_LEADS_UPLOAD },
    { key: '3', title: 'Downloads', path: Routes.MY_LEADS_DOWNLOAD },
  ]

  const campaignsSteps = [
    { key: 1, title: 'Search My Leads' },
    { key: 2, title: 'Specify Message' },
    { key: 3, title: 'Personalize' },
    { key: 4, title: 'Download' },
  ]

  return (
    <aside className={'my-leads sidebar-wrapper'}>
      {campaignLocation
        ? <Flex vertical className={'campaign-sidebar'}>
            <OrderedList items={campaignsSteps} activeItem={activeCampaignItem} />

            <Button className={'back-button'} iconPosition={'start'} onClick={() => navigate(-1)} type={'text'} icon={<SvgIcon type={'arrowLeft'} />}>
              Back
            </Button>
          </Flex>
        : <UnorderedNavigationList items={sidebarList} />
      }
    </aside>
  )
}
