import { FC } from "react"
import { UnorderedNavigationList } from "src/components/navigation-unordered-list"
import { Routes } from "src/types/routes"

export const Sidebar: FC = () => {
  const sidebarList = [
    { path: Routes.LEARNING_CENTER_DATA_DICTIONARY, title: 'Data dictionary' },
    { path: Routes.LEARNING_CENTER_VIDEO_TUTORIALS, title: 'Video tutorials' },
  ]
  
  return (
    <aside className={'learning-center sidebar-wrapper'}>
      <UnorderedNavigationList items={sidebarList} />
    </aside>
  )
}