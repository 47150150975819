import { FC } from "react";
import { useLocation } from "react-router-dom";
import './Sidebar.less'
import { OrderedList } from "src/components/ordered-list";

export const Sidebar: FC = () => {
  const location = useLocation()
  const activeItem = location.state?.step.toString()
  
  const sidebarList = [
    { key: 1, title: 'Define your Search' },
    { key: 2, title: 'Preview' },
    { key: 3, title: 'Download' },
  ]

  return (
    <div className={'search-and-download sidebar-wrapper'}>
      <OrderedList items={sidebarList} activeItem={activeItem || '1'} />
    </div>
  )
}