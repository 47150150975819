import { App } from "src/types"
import fetchClient from "./fetchClient"

interface ChangePassword {
  password: string
  confirmPassword: string
}

interface UpdateProfile {
  firstName: string
  lastName: string
  email: string
  phone: string
  companyName: string
  companyIndustryId: number
  locationCountryCode: string
  locationStateCode: string
  subscriptionType: string
  credits: number
}

interface RequestAssistance {
  subject: string
  message: string
}

export const changePassword = async (values: ChangePassword) => {
  const res = await fetchClient.post("/user/change-password", values)
  return res.data
}

export const updateProfile = async (values: Partial<UpdateProfile>) => {
  const res = await fetchClient.patch("/user/profile/update/submit", values)
  return res.data
}

export const getLocationAndIndustries = async () => {
  const res = await fetchClient.get("/user/profile/update")
  return res.data
}

export const requestAssistance = (values: RequestAssistance) => {
  const res = fetchClient.post("/user/help-request", values)
  return res
}
