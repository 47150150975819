import { useMutation, useQuery, useQueryClient } from "react-query"
import {
  LeadsResponse,
  createLeadsExportRequest,
  getLeadsTransactionById,
  getLeadsTransactions,
  getMyLeads,
  getMyLeadsEnhance,
  getMyLeadsEnhancePreview,
} from "src/api/leadsApi"
import { LeadsFilter } from "src/module/all-leads"
import { App } from "src/types"

export const useLeadsApi = () => {
  const queryClient = useQueryClient()

  const myLeadsQuery = (
    type: "all" | "my-leads" | "new-leads" | "shared",
    page: number,
    limit: number,
    filters: LeadsFilter
  ) => {
    return useQuery<LeadsResponse, Error>(
      ["myLeads", type, page, limit, filters],
      () => getMyLeads(type, page, limit, filters),
      {
        keepPreviousData: true,

        onSuccess: data => {
          queryClient.setQueryData<LeadsResponse>(["myLeads", type, page, limit, filters], data)
        },
        onError: error => {
          queryClient.setQueryData<LeadsResponse>(["myLeads", type, page, limit], {
            type,
            page,
            limit,
            data: [],
            total: 0,
          })
        },
      }
    )
  }

  const leadTransactionById = (transactionId: string, options = {}) => {
    return useQuery<App.LeadsTransaction, Error>(
      ["leadTransactionById", transactionId],
      () => getLeadsTransactionById(transactionId),
      {
        keepPreviousData: true,
        onSuccess: data => {
          queryClient.setQueryData<App.LeadsTransaction>(["leadTransactionById", transactionId], data)
        },
        onError: error => {
          queryClient.setQueryData<App.LeadsTransaction>(["leadTransactionById", transactionId], {
            transactionId,
            creditsEarned: 0,
            duplicates: 0,
            enhances: 0,
            totalLeads: 0,
            invalidLeadsCount: 0,
            discoveredLeads: 0,
            status: "pending",
            fileName: "",
            uploadDate: "",
            verifiedLeads: 0,
            type: "upload",
          })
        },
        ...options,
      }
    )
  }

  const leadsTransactions = (type: "upload" | "download", page: number, limit: number) => {
    return useQuery<App.LeadsTransactions, Error>(
      ["leadsTransactions", type],
      () => getLeadsTransactions(type, page, limit),
      {
        keepPreviousData: true,
        onSuccess: data => {
          queryClient.setQueryData<App.LeadsTransactions>(["leadsTransactions", type], data)
        },
        onError: error => {
          queryClient.setQueryData<App.LeadsTransactions>(["leadsTransactions", type], {
            transactions: [],
            transactionsTotal: 0,
            transactionType: type,
          })
        },
      }
    )
  }

  const exportLeads = useMutation(createLeadsExportRequest)
  const enhanceLeads = useMutation(getMyLeadsEnhance)
  const enhanceLeadsPreview = useMutation(getMyLeadsEnhancePreview)

  return {
    leadsTransactions,
    leadTransactionById,
    enhanceLeadsPreview,
    myLeadsQuery,
    enhanceLeads,
    exportLeads,
  }
}
