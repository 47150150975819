import { Button, Flex } from "antd"
import { FC } from "react"
import { UnorderedNavigationList } from "src/components/navigation-unordered-list"
import { Routes } from "src/types/routes"
import { SvgIcon } from "../../../components/icon"
import { useAuth } from "../../../contexts/AuthProvider"

export const Sidebar: FC = () => {
  const { logout } = useAuth()
  const list = [
    { title: "Profile", path: Routes.PROFILE_USER_DATA },
    { title: "Subscription", path: Routes.PROFILE_SUBSCRIPTION },
    { title: "Password", path: Routes.PROFILE_CHANGE_PASSWORD },
  ]

  return (
    <Flex vertical={true} style={{ height: "calc(100vh - 120px)", paddingBottom: 32 }} justify={"space-between"}>
      <UnorderedNavigationList items={list} />
      <Button
        onClick={() => logout()}
        size={"large"}
        style={{
          background: "none",
          border: "none",
          color: "#fff",
          fontSize: 16,
          paddingLeft: 0,
          justifyContent: "left",
        }}
      >
        <Flex gap={8} align={"center"}>
          <SvgIcon type={"signOut"} />
          <span>Log Out</span>
        </Flex>
      </Button>
    </Flex>
  )
}
