export enum Routes {
  SIGN_UP = "/sign-up",
  SIGN_IN = "/sign-in",
  CONFIRM_EMAIL = "/confirm-email",
  DASHBOARD = "/dashboard",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password",
  ENHANCE_AND_SHARE = "/enhance-and-share",
  SEARCH_AND_DOWNLOAD = "/search-and-download",
  MY_LEADS = "/my-leads",
  MY_LEADS_ALL_LEADS = "/my-leads/all-leads",
  MY_LEADS_UPLOAD = "/my-leads/upload",
  MY_LEADS_DOWNLOAD = "/my-leads/download",
  CAMPAIGN = "/campaigns",
  PERSONALIZED_CAMPAIGN = "/campaigns/personalized-communications",
  PROFILE = "/profile",
  PROFILE_USER_DATA = "/profile/user-data",
  PROFILE_SUBSCRIPTION = "/profile/subscription",
  PROFILE_CHANGE_PASSWORD = "/profile/change-password",
  LEADS_DEFINE_SEARCH = "/search-and-download/define-search",
  LEADS_PREVIEW = "/search-and-download/preview",
  LEADS_DOWNLOAD = "/search-and-download/download",
  LEARNING_CENTER = "/learning-center",
  LEARNING_CENTER_DATA_DICTIONARY = "/learning-center/data-dictionary",
  LEARNING_CENTER_VIDEO_TUTORIALS = "/learning-center/video-tutorials",
}
