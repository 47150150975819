import classNames from "classnames";
import { FC } from "react";
import './index.less'

interface Item {
  key: number
  title: string
  path?: string
}

type Props = {
  items: Item[]
  activeItem: string
}

export const OrderedList: FC<Props> = ({ items, activeItem }) => {
  return (
    <ol className={'ordered-list'}>
        {items.map((item, index) => (
          <li key={item.key} className={'list-item'}>
            <div 
              className={classNames(
                'list-item-text',
                { 'active': activeItem === item.key.toString() }
              )}
            >
              <div className={'step-number'}>
                {index + 1}
              </div>

              <div className={'text-title body-md-light'}>
                {item.title}
              </div>
            </div>
          </li>
        ))}
      </ol>
  )
}