import { Button, ButtonProps } from "antd";
import { FC } from "react";
import { IconsType, SvgIcon } from "src/components/icon";
import './index.less'

type Props = {
  iconType: IconsType
} & ButtonProps

const ButtonWithIcon: FC<Props> = ({
  iconType,
  ...props
}) => {
  return (
    <Button 
      {...props} 
      icon={<SvgIcon type={iconType} />}
      className={`button-with-icon ${props.className || ''}`} 
    />
  )
}

export default ButtonWithIcon