import classNames from "classnames";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import './index.less'

interface Item {
  title: string
  path: string
}

type Props = {
  items: Item[]
}

export const UnorderedNavigationList: FC<Props> = ({ items }) => {
  return (
    <nav className={'navigation-unordered-list'}>
      <ul className={'navigation-list'}>
        {items.map(item => (
          <li key={item.path} className={'navigation-item'}>
              <NavLink 
                to={item.path} 
                className={({ isActive }) => classNames(
                  'item-link',
                  { 'active': isActive }
                )}
              >
                <div className={'item-dot'} />

                <div className={'item-title body-md-light'}>
                  {item.title}
                </div>
              </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}