export const handleSubscribe = async (productId: string) => {
  try {
    // Call your backend to create a Stripe Checkout Session
    const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}/api/v1/payments/create-checkout-session`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ productId }),
    })
    const { url } = await response.json()
    // Redirect to Stripe Checkout
    window.location.href = url
  } catch (error) {
    console.error("Error initiating subscription:", error)
  }
}
