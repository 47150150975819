import { Typography } from "antd"
import { Dispatch, FC, SetStateAction } from "react"
import { SvgIcon } from "src/components/icon"
import CustomButton from "src/components/ui/CustomButton"
import "./SubscriptionCard.less"

interface Props {
  plan: {
    title: string
    text: string
    price: string
    features: string[]
  }
  productId?: string
  handleSubscribe?: (productId: string) => void
  setSelectedPlan?: Dispatch<SetStateAction<string>>
  isForModal?: boolean
  isActive?: boolean
  isSelected?: boolean
}

export const SubscriptionCard: FC<Props> = ({setSelectedPlan, productId, handleSubscribe, plan, isForModal, isActive, isSelected }) => {
  const { title, text, price, features } = plan
  return (
    <div className={`subscription-card ${isActive ? "active" : "not-active"} ${isSelected ? "selected" : ""}`} onClick={() => setSelectedPlan ? setSelectedPlan(plan?.title!) : ""}>
      <Typography.Text className={"title"}>{title}</Typography.Text>
      <Typography.Text className={"text"}>{text}</Typography.Text>
      <div className='price-content'>
        <Typography.Text className={"price"}>{price}</Typography.Text>
        {price !== "Free" ? <Typography.Text className={"text"}>/month</Typography.Text> : <div className='divider' />}
      </div>
      {!isForModal ? (
        isActive ? (
          <CustomButton text={"Current Plan"} type={"default"} />
        ) : (
          <CustomButton onClick={() => handleSubscribe && handleSubscribe(productId as string)} text={"Choose Plan"} type={"primary"} className='choose-plan-button' />
        )
      ) : (
        <></>
      )}
      <div className='features'>
        {features.map((feature, index) => (
          <div className='feature-wrapper' key={index}>
            <SvgIcon type={"checkmarkCircle"} className='icon' />
            <Typography.Text className={"feature"}>{feature}</Typography.Text>
          </div>
        ))}
      </div>
    </div>
  )
}
